<script setup lang="ts">
  import {computed} from 'vue';
  import {useI18n} from 'vue-i18n';
  import useCpBem from '~/composables/useCpBem';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import CpProductImage from '~/components/cp/CpProductImage/CpProductImage.vue';
  import CpPillCard from '~/components/cp/CpPill/CpPillCard/CpPillCard.vue';
  import CpProductRate from '~/components/cp/CpProductRate/CpProductRate.vue';
  import {getEtaMessage} from '~/utils/etaUtils';
  import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';
  import type {IEtaInfo} from '~/types';

  const props = defineProps<{
    kind: 'FeaturedOffer'|'Standard'|'Offer'|'Featured'|'Oos'|'OosOffer',
    productType: 'physical'|'esd',
    pillLarge: boolean,
    pillShort: boolean,
    rate: boolean,
    rateValue: number,
    reviews: number,
    freeShipping: boolean,
    link: string,
    title: string,
    hasDiscount: boolean,
    discount: string | boolean,
    picture: string,
    imageTitle: string,
    price: number,
    tPrice: number,
    id: string,
    eta: boolean,
    etaData?: IEtaInfo | { messages: IEtaInfo['messages'] }
  }>();

  const {b, e, em} = useCpBem('cp-product-card-list');
  const {t} = useI18n();
  const displayMessage = computed(() => getEtaMessage(props.etaData));
  const priceFormat = props.price.toLocaleString('es-MX', {style: 'currency', currency: 'MXN'});
  const tPriceFormat = props.tPrice.toLocaleString('es-MX', {style: 'currency', currency: 'MXN'});
</script>

<template>
    <CpNuxtLink :class="b" page-name="details" :link="link">
        <div>
            <!--Pills-->
            <div v-if="kind || productType === 'esd'" :class="em('pills', {'catalog': eta})">
                <CpPillCard
                    v-if="kind === 'Featured'"
                    variant="featuredProduct"
                    kind="standard"
                    :class="e('first')"
                />
                <CpPillCard
                    v-if="discount && kind === 'FeaturedOffer'"
                    variant="featuredProduct"
                    kind="discount"
                    :discount="discount"
                    :class="e('first')"
                />
                <CpPillCard
                    v-if="discount && kind === 'Offer'"
                    variant="offer"
                    :discount="discount"
                    :class="e('first')"
                />
                <CpPillCard
                    v-if="kind === 'Oos' || kind === 'OosOffer'"
                    variant="oosEol"
                    :class="e('first')"
                />
                <CpPillCard
                    v-if="productType === 'esd' && pillLarge"
                    variant="esd"
                    :class="e('first')"
                />
                <CpPillCard
                    v-if="productType === 'esd' && pillShort"
                    variant="esd"
                    short
                    :class="kind !== 'Standard' ? e('esd-pill') : ''"
                />
            </div>
            <!--Image-->
            <div :class="e('image-container')">
                <CpProductImage
                    :class="e('image')"
                    :picture="picture"
                    :title="imageTitle"
                    :height="120"
                    :width="120"
                />
            </div>
        </div>
        <div :class="e('right-container')">
            <!--Product Info-->
            <div :class="em('info-container', {'eta': eta})">
                <CpText
                    :class="e('text')"
                    variant="body-regular"
                    type="primary"
                    data-testid="product-card-list-text"
                >
                    {{ title }}
                </CpText>
                <CpProductRate
                    v-if="rate"
                    :rate="rateValue"
                    :total-reviews="reviews"
                    :hide-count="reviews < 1"
                />
                <div v-if="eta && productType === 'physical' && displayMessage" :class="e('eta')">
                    <CpTextParser :text="displayMessage" variant="caption" />
                </div>
                <div v-if="eta && productType === 'esd'" :class="e('eta')">
                    <CpText variant="caption-bold">
                        {{ t('immediateDelivery') }}
                    </CpText>
                    <CpText variant="caption-regular">
                        {{ t('processingOrder') }}
                    </CpText>
                </div>
                <CpText
                    v-if="freeShipping && productType !== 'esd'"
                    variant="caption-bold"
                    type="mkt-dark"
                    :class="e('link')"
                >
                    {{ t('freeShipping') }}
                </CpText>
            </div>
            <!--Price-->
            <div :class="e('price-container')">
                <CpText v-if="hasDiscount" :class="e('discount-price')" variant="price-from" type="neutral">
                    {{ tPriceFormat }}
                </CpText>
                <CpText
                    :variant="hasDiscount ? 'price-offer' :'price-regular'"
                    :type="hasDiscount ? 'mkt-dark' : 'neutral-dark'"
                >
                    {{ priceFormat }}
                </CpText>
            </div>
        </div>
    </CpNuxtLink>
</template>

<style scoped lang="scss">
  .cp-product-card-list {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    height: 100%;
    text-decoration: none;
    &__row {
      display: flex;
    }
    &__column {
      display: flex;
      flex-direction: column;
    }
    &__first {
      z-index: 1;
    }
    &__right-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      height: 100%;
      gap: 8px;
    }
    &__pills {
      margin-top: -13px;
      margin-left: -9px;
      display: flex;
      position: absolute;
        &--catalog {
          margin-top: -22px;
        }
    }
    &__text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__image-container {
      height: 120px;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__info-container {
      height: 88px;
      display: flex;
      flex-direction: column;
      &--eta {
        height: 116px;
      }
    }
    &__eta {
      line-height: 12px;
      margin-top: 4px;
    }
    &__price-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap:8px;
      height: fit-content;
    }
    &__discount-price {
      text-decoration: line-through;
    }

    &__esd-pill {
      margin-left: -6px;
    }
  }
</style>

<i18n locale="es-US">
    freeShipping: "+Envío gratis"
    receiveIt: "Recibelo entre el "
    immediateDelivery: "Entrega inmediata "
    processingOrder: "al procesar tu pedido"
</i18n>
