<script setup lang="ts">
    import useCpBem from '~/composables/useCpBem';
    import CpProductCardList from '~/components/cp/CpProductCard/CpProductCardList/CpProductCardList.vue';

    defineProps<{
      kind: 'FeaturedOffer'|'Standard'|'Offer'|'Featured'|'Oos'|'OosOffer',
      productType: 'physical'|'esd',
      pillLarge: boolean,
      pillShort: boolean,
      rate: boolean,
      rateValue: number,
      reviews: number,
      freeShipping: boolean,
      link: string,
      title: string,
      hasDiscount: boolean,
      discount: string | boolean,
      picture: string,
      imageTitle: string,
      price: number,
      tPrice: number,
      id: string,
    }>();

    const {b, e} = useCpBem('cp-product-card-stream');
</script>

<template>
    <div :class="b">
        <CpProductCardList
            :id="id"
            :kind="kind"
            :product-type="productType"
            :pill-large="pillLarge"
            :pill-short="pillShort"
            :rate="rate"
            :rate-value="rateValue"
            :reviews="reviews"
            :free-shipping="freeShipping"
            :link="link"
            :title="title"
            :has-discount="hasDiscount"
            :discount="discount"
            :picture="picture"
            :image-title="imageTitle"
            :price="price"
            :t-price="tPrice"
        />
    </div>
</template>

<style scoped lang="scss">
    .cp-product-card-stream {
      border: 1px solid var(--color-neutral-extra-light);
      border-radius: 8px;
        //max-width: 330px;
        height: 146px;
    }
</style>
